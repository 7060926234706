.App {
  text-align: center;
}

.info-background-container {
  /* Set the position to relative to allow positioning of ::before pseudo-element */
  position: relative;
}

.info-background {
  /* Set the text color to white for better visibility */
  color: white;

  /* Add padding for better readability */
  padding: 20px;

  /* Create the background using ::before pseudo-element */
  position: relative;
  z-index: 1;
}

.info-background::before {
  /* Set the background color with a semi-transparent black */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the last value for transparency */
  z-index: -1;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.subtext {
  font-size: 0.8em;
  margin-bottom: 50px;
}

/* src/App.css */

.about-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* To ensure items stack vertically */
  text-align: center;
  /* other existing styles */
}


.span-style {
  font-style: italic;
  text-decoration: underline;
}

.video {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
}

.images {
  display:flex;
  max-width: 100%;
}

.title {
  margin-bottom: 25px;
}

.coming-soon {
  margin-top: 150px;
}